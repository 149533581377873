import { default as bloqueo_45ipWe9uIpppb2Meta } from "/var/www/biopas-programa-paciente-form/pages/bloqueo-ip.vue?macro=true";
import { default as indexfwduZ511RKMeta } from "/var/www/biopas-programa-paciente-form/pages/index.vue?macro=true";
export default [
  {
    name: "bloqueo-ip",
    path: "/bloqueo-ip",
    component: () => import("/var/www/biopas-programa-paciente-form/pages/bloqueo-ip.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/biopas-programa-paciente-form/pages/index.vue").then(m => m.default || m)
  }
]